import React from 'react';

const Download= ({children}) => {

    return <div id="otherplatforms" style={{marginTop: '160px',marginBottom: '200px'}} class="container">
    <div class="row">
      <section class="card-holder">
        
            {children}
        
      </section>
    </div>
  </div>;
  }

  export default Download;