import React from 'react';
import logo from './logo.png';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile} from "react-device-detect";
import Languages from '../translate/Languages'

const Header= (props) => {

  const privacypolicy = ()=>{
    if(Languages.getLang()==="pe")
      return;
    return <li class="header__el"><a href="https://smartmedialtd.co.uk/privacy-policy/" class="header__link">Privacy Policy</a></li>
  }

   let renderContent = ()=>{

    if(isBrowser)
    {
      console.log(isBrowser)
      return <div>
      <div class="header__logo"><img className="header__img"  style={{width:'60px'}} src={logo}></img> <h1 class="header__title">Asman-app<span class="header__light">.com</span></h1></div>
      <div class="header__menu">
      <nav id="navbar" class="header__nav collapse">
        <ul class="header__elenco">
          <li class="header__el"><a href="#options" class="header__link">{Languages.translate("Plans")}</a></li>
          {
            privacypolicy()
          }
          <li class="header__el"><a href="https://smartmedialtd.co.uk/contact/" target="_blank" class="header__link">{Languages.translate("Contact Us")}</a></li>
          <li class="header__el header__el--blue"><a href="https://smartmedialtd.co.uk/" class="btn btn--white">{Languages.translate("more")}  →</a></li>
        </ul>
      </nav>
    </div>
    </div>
    } else {
      return <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
      <span class="sr-only">close</span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      <span class="icon-bar"></span>
      </button>
    }

  }

    return  renderContent()
  //   <header class="header">
  //   <div class="container header__container"> 
  //     {
  //       renderContent()
  //     }
  //   </div>
  // </header>;
  
  }

  export default Header;


  // Languages.translate("Learn React")