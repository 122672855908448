import React from 'react';
import Languages from '../translate/Languages'

const PlansCard= (props) => {
    
    const details = props.Details;
    
    const counterAdd = (text) =>
    {
        if(Languages.getLang()==="en"){
            return (
                <li class="price-box__list-el_left">{text}</li>
            )
        } else {
            return (
                <li class="price-box__list-el_right">{text}</li>
            )
        }
    }

    const priceRender = (price) =>
    {
        if(price===""){
            return (
              <h2 class="price-box__discount"><span class="price-box__dollar" style={{fontWeight: 'bold',fontSize: 'larger'}}>{Languages.translate("Free")}</span></h2>
            )
        } else {
            return (
              <h2 class="price-box__discount"><span class="price-box__dollar">{Languages.translate("Tomans")}</span>{Languages.translateNumeric(price)}<span class="price-box__discount--light">/{Languages.translate("year")}</span></h2>
            )
        }
    }

    return <div class={"col-md-4 col-sm-4 price-box price-box--"+props.ColorTheme}>
    <div class="price-box__wrap" style={{marginTop: '80px'}}>
      <div class="price-box__img"></div>
      <h1 class="price-box__title">
        {props.ModuleName}
      </h1>
          {
            priceRender(props.Price)
          }
      <p class="price-box__feat">
        {Languages.translate("Abilities")}
      </p>
      <ul class="price-box__list" style={{ textAlign:(Languages.getLang()==="en")?'left':'right' }}>
        {
            details.map(text => counterAdd(text) )
        }
      </ul>
       <div class="price-box__btn">
      <a href="#otherplatforms" class={"btn btn--width btn--"+props.ColorTheme}>{Languages.translate("Download")}</a>
    </div>
  </div>
  </div>;
  
  }

  export default PlansCard;