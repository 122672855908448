import './App.css';
import './bs.css'
import './style.css'
import React,{ useMemo } from 'react';
import Header from './asman-app/Header.jsx'
import Top from './asman-app/Top.jsx'
import LanguageBTN from './LanguageBTN.jsx'
import Footer from './asman-app/Footer.jsx'
import ContactUs from './asman-app/ContactUs.jsx'
import Download from './asman-app/Download.jsx'
import DownloadCard from './asman-app/DownloadCard.jsx'
import Plans from './asman-app/Plans.jsx'


import windows from './asman-app/windows.png';
import macos from './asman-app/macos.png';
import ios from './asman-app/ios.png';
import android from './asman-app/droid.png';

function App() {

  const [downloadLinks, setDownloadLinks] = React.useState({});
  const [langs, setLangs] = React.useState(0);
  const changeLang = event => setLangs(langs + 1);


  const getDownloadLinks= () => {
    fetch("/links")
    .then( res => res.json() )
    .then( data => setDownloadLinks(data) )
    .catch( error => console.log(error) )
  }

  useMemo(() => {
    getDownloadLinks()
  }, []);

  // const downloadLinks = getDownloadLinks();



  return (
    <div className="App">
      <Header/>
      <Top
        DownloadLink={downloadLinks["windows"]}
      />
      <Plans/>
      <Download>
          <DownloadCard
            Logo={windows}
            PlatformName={"Windows"}
            DownloadLink={downloadLinks["windows"]}
          />
          <DownloadCard
            Logo={macos}
            PlatformName={"macOS"}
            DownloadLink={""}
          />
          <DownloadCard
            Logo={ios}
            PlatformName={"iOS"}
            DownloadLink={downloadLinks["appstoresite"]}
          />
          <DownloadCard
            Logo={android}
            PlatformName={"Android"}
            DownloadLink={downloadLinks["playstoresite"]}
            DirectFileLink={downloadLinks["apkfile"]}
          />
      </Download>
      <ContactUs/>
      <Footer/>
      <LanguageBTN onClick={changeLang}></LanguageBTN>
    </div>
  );
}

export default App;
