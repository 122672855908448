import React,{useState} from 'react';
import Languages from './translate/Languages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage  } from '@fortawesome/free-solid-svg-icons';

const LanguageBTN= (props) => {

  const {onClick} = props;
  

    return <div className="langBTN noSelect" onClick={()=>{
      if(Languages.getLang()!=="en"){
        Languages.setLang("en");
      } else {
        Languages.setLang("pe");
      }
      onClick();
      }}>
      {Languages.getLang()==="en"?"پارسی":"English"}&nbsp;&nbsp;
      <FontAwesomeIcon icon={faLanguage} /> 
    </div>;
  }

  export default LanguageBTN;