import React from 'react';
import PlansCard from './PlansCard'
import Languages from '../translate/Languages'

const Plans= (props) => {

    const trnslt = Languages;

    return <div id="options" class="sect sect--padding-bottom">
    <div class="container">
    <div class="row row--center">
      <h1 class="row__title">
        {trnslt.translate("Plans")}
      </h1>
      <h2 class="row__sub">{Languages.translate("Which do you need?")}</h2>
    </div>


    <PlansCard
        ModuleName={"FollowUp"}
        ColorTheme={"purple"}
        Price={"1.200.000"}
        Details={[
            trnslt.translate("Upload customer files"),
            trnslt.translate("List of followup customers"),
            trnslt.translate("Create a variety of questions"),
            trnslt.translate("Comprehensive report")
        ]}
    />

    <PlansCard
        ModuleName={"CSI"}
        ColorTheme={"violet"}
        Price={"1.500.000"}
        Details={[
            trnslt.translate("Data entry"),
            trnslt.translate("Data analysis"),
            trnslt.translate("Check the data"),
            trnslt.translate("Calc weight"),
            trnslt.translate("Comprehensive report")
        ]}
    />

    <PlansCard
        ModuleName={"CRM"}
        ColorTheme={"blue"}
        Price={"600.000"}
        Details={[
            trnslt.translate("Submit requests"),
            trnslt.translate("Track status"),
            trnslt.translate("Comprehensive report")
        ]}
    />

    <PlansCard
        ModuleName={trnslt.translate("Rejected")}
        ColorTheme={"blue"}
        Price={"600.000"}
        Details={[
            trnslt.translate("Fast registration with mobile"),
            trnslt.translate("Submit requests"),
            trnslt.translate("Track status"),
            trnslt.translate("Comprehensive report")
        ]}
    />

    <PlansCard
        ModuleName={trnslt.translate("Parts Prices")}
        ColorTheme={"blue"}
        Price={""}
        Details={[
            trnslt.translate("Announce inventory of parts at the time of acceptance"),
            trnslt.translate("Announce the price of parts at the time of acceptance"),
            trnslt.translate("Ability to search for parts by car name and type"),
            trnslt.translate("Calculate the price including taxes")
        ]}
    />

    <PlansCard
        ModuleName={trnslt.translate("Approximate cost")}
        ColorTheme={"blue"}
        Price={"600.000"}
        Details={[
            trnslt.translate("Announce the exact cost of repairs"),
            trnslt.translate("Announce inventory of parts at the time of acceptance")
        ]}
    />

    <PlansCard
        ModuleName={trnslt.translate("Sale")+" FollowUp"}
        ColorTheme={"violet"}
        Price={"1.200.000"}
        Details={[
            trnslt.translate("Upload customer files"),
            trnslt.translate("List of followup customers"),
            trnslt.translate("Create a variety of questions"),
            trnslt.translate("Comprehensive report")
        ]}
    />

    <PlansCard
        ModuleName={trnslt.translate("Sale")+" CIS"}
        ColorTheme={"purple"}
        Price={"1.500.000"}
        Details={[
            trnslt.translate("Data entry"),
            trnslt.translate("Data analysis"),
            trnslt.translate("Check the data"),
            trnslt.translate("Calc weight"),
            trnslt.translate("Comprehensive report")
        ]}
    />

  </div>  
  </div>;
  
  }

  export default Plans;