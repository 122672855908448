import React from 'react';
import Languages from '../translate/Languages'

const DownloadCard= (props) => {

    const directFile = (link)=>{
      if(link===undefined)
      {
        return;
      }
      return <div class="w-100 text-center mt1"> <a href={link} type="button" style={{color:'black'}}>{Languages.translate("APK File")}</a> </div>;
    }

    return <div class="col-md-3 col-sm-6">
    <div class="card-container manual-flip">
      <div class="card">
        <div class="front">
          <div class="cover"></div>
          <div class="branded">
            <img width="100px"  src={props.Logo}></img>
            {
              directFile(props.DirectFileLink)
            }
          </div>
          <div class="content">
            <div class="main" >
              <a href={props.DownloadLink} target="_blank" rel="external" type="button" class="btn btn-default btn-block btn-download">{props.PlatformName}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
  }

  export default DownloadCard;