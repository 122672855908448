import React from 'react';
import bg from './path3762.png';
import Languages from '../translate/Languages'

const ContactUs= (props) => {

    return <div class="sect sect--violet ">
        
    <img src={bg} class="career-img"></img>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 class="career_title" style={{ textAlign:(Languages.getLang()==="en")?'left':'right' }} >{Languages.translate("Are you looking for unique capabilities for your management system?")}</h1>
          <h1 class="career_sub" style={{ textAlign:(Languages.getLang()==="en")?'left':'right' }} >{Languages.translate("Talk to us about it")}</h1>
          <div style={{ textAlign:(Languages.getLang()==="en")?'right':'left' }}> <a href="https://smartmedialtd.co.uk/contact/" target="_blank" class="btn btn--white btn--width">{Languages.translate("Contact Us")}</a> </div>
        </div>
      </div>
    </div>

  </div>;
  }

  export default ContactUs;