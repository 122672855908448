import React from 'react';
import logo from './logo.png';
import Languages from '../translate/Languages'

const Footer= (props) => {

    return <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-xs-6">
         <img class="footer__img" style={{width: '30px'}} src={logo}></img> <h1 class="footer__title">Asman-app<span class="footer__light">.com</span></h1></div>
        <div class="col-md-10 col-xs-6">
          <div class="footer__social">
          </div>
        </div>
      </div>
    </div>
  </footer>;
  }

  export default Footer;