import strings from './strings.json'

class Languages {

  static instans=undefined;
  static getInstans()
  {
      if(Languages.instans===undefined)
      {
          console.log("New Languages Created!!!")
          Languages.instance = new Languages();
      }
      return Languages.instance;
  }

    constructor(){
      this._lang="en";
      this._translates = {};
      try {
        this._translates = strings;
      } catch(e){console.log("string parse error")}
      this._PeNums={
        "0":"۰",
        "1":"۱",
        "2":"۲",
        "3":"۳",
        "4":"۴",
        "5":"۵",
        "6":"۶",
        "7":"۷",
        "8":"۸",
        "9":"۹",
        ".":",",
        ",":","
              };
    }
  
    setLang(lang)
    {
      this._lang=lang;
    }
    getLang()
    {
      return this._lang;
    }

    translate(str)
    {
      if(this._translates.hasOwnProperty(str)){
        if(this._translates[str].hasOwnProperty(this._lang))
        {
          return this._translates[str][this._lang];
        }
      }
      return str;
    }

    translateNumeric(str)
    {
      if(this._lang==="en")
      {
        return str;
      } else {
        
        var newSTR="";
        for(var char in str)
        {
          newSTR=newSTR+this._PeNums[str[char]];
        }
        return newSTR;

      }
    }
    
  }



  
  export default Languages.getInstans();