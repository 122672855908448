import React from 'react';
import laptop from './laptop.svg';
import windows from './windows.png';
import Languages from '../translate/Languages'

const Top= (props) => {

    return <div class="sect sect--padding-top">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
      <div class="site">
        <h1 class="site__title">{Languages.translate("After Sales Service Process Management")}</h1>
        <h2 class="site__subtitle">{Languages.translate("Manage Professionally")}</h2>
        <div class="site__box-link">
          <a class="btn btn--width" href={props.DownloadLink}>
            <span><img style={{width:'20px'}} src={windows}></img>&nbsp;&nbsp;&nbsp;</span>{Languages.translate("Download")}</a>
          <a class="btn btn--revert btn--width" href="#otherplatforms">{Languages.translate("Other Platforms")}</a>
        </div>
        <img class="site__img" src={laptop}></img>
      </div>
      </div>
      </div>
    </div>
  </div>;
  
  }

  export default Top;